import { Injectable } from '@angular/core';
import { map, Subject } from "rxjs";
import { IRoamNavigation } from "@app/shared/interfaces/sidebar-menu.interface";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RoamNavigationService {

  public navigation: Subject<IRoamNavigation> = new Subject<IRoamNavigation>();
  private _shortMenu: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public _shortMenuList: string[] = ['messages', 'inbox', 'calendar']

  constructor() { }

  get shortMenu(): string {
    return this._shortMenu.getValue();
  }

  set shortMenu(value: string) {
    this._shortMenu.next(value);
  }

  public shortMenuVisible$ = this._shortMenu.pipe(
    map(value => this._shortMenuList.includes(value))
  );
}
